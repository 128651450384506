<template>
  <div>
    <div id="content-to-print" ref="pdfContent">
      <!-- 这里是你要导出的内容 -->
      <h1>Hello, World!</h1>
      <p>This is a PDF generated by Vue and jsPDF.</p>
    </div>
    <button @click="exportPDF">导出PDF</button>
  </div>
</template>
 
<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
 
export default {
  methods: {
    exportPDF() {
      const content = this.$refs.pdfContent;
      html2canvas(content).then(canvas => {
        const pdf = new jsPDF();
        const imgData = canvas.toDataURL('image/png');
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('download.pdf');
      });
    }
  }
};
</script>